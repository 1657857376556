import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { MatStep } from '@angular/material/stepper';
import { ProfileService } from '../../profile.service';
import { MessageService } from '../../message.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  email = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('', [Validators.required, Validators.minLength(6)]);
  // nickname = new FormControl('', [Validators.required, Validators.maxLength(12)]);
  code = new FormControl('', [Validators.required, Validators.minLength(6)]);

  validSignUp: boolean;
  errMsgSignUp: string;
  validCode: boolean;
  errMsgCode: string;

  constructor(private router: Router, private profileService: ProfileService, private messageService: MessageService) { }

  private log(uMessage: string) {
    this.messageService.add(`SignupComponent: ${uMessage}`);
  }

  ngOnInit() {

  }

  /* getNicknameErrorMessage() {

    if (this.nickname.hasError('required')) {
      return 'You must enter a nickname';
    }

    if (this.nickname.hasError('maxlength')) {
      return 'Password must be less than 12';
    }

    return '';
  } */

  getEmailErrorMessage() {

    if (this.email.hasError('required')) {
      return 'You must enter an email';
    }

    if (this.email.hasError('email')) {
      return 'You must enter valid email';
    }

    return '';
  }

  getPwdErrorMessage() {

    if (this.password.hasError('required')) {
      return 'You must enter a password';
    }

    if (this.password.hasError('minlength')) {
      return 'Password must be more than 6';
    }

    return '';
  }

  getCodeErrorMessage() {

    if (this.password.hasError('required')) {
       return 'You must enter a code';
    }

    return '';
  }

  onSubmitSignup(uStepper: MatStepper, uStep: MatStep): void {
    // Process checkout data here
    this.log('ConfrimSubmit has been submitted');
    this.log('[email:password] [' + this.email + ':' + this.password + ']');

    this.profileService.signUp(this.email.value, this.password.value).then(res => {
        this.log(JSON.stringify(res));
        if (res) {
          uStep.completed = true;
          uStepper.next();
        } else {
          this.errMsgSignUp = this.profileService.getErrMsg();
          this.validSignUp = false;
          this.log('signup failed.' + this.errMsgSignUp);
        }
      }
    );
  }

  onSubmitConfirm(uStepper: MatStepper, uStep: MatStep): void {
    // Process checkout data here
    this.log('ConfrimSubmit has been submitted');

    this.profileService.confirmSignUp(this.email.value, this.code.value).then(res => {
        this.log(JSON.stringify(res));
        // after signup
        // this.profileService.addProfile(this.email.value, this.nickname.value);
        if (res) {
          uStep.completed = true;
          uStepper.next();
          setTimeout(() => {
              this.router.navigate(['/signin']);
          }, 3000);  // 3s
        } else {
          this.errMsgCode = this.profileService.getErrMsg();
          this.validCode = false;
          this.log('confirmsignup failed.' + this.errMsgCode);
        }
      }
    );
  }

  onResendCode(): void {
    this.log('onResendCode has been submitted');

    this.profileService.resendCode(this.email.value).then(res => {
        console.log(JSON.stringify(res));
        if (res) {
          // show success message using error message.
          this.validCode = false;
          this.errMsgCode = 'Verification code was resent.';
        } else {
          this.errMsgCode = this.profileService.getErrMsg();
          this.validCode = false;
          this.log('onResendCode failed.' + this.errMsgCode);
        }
      }
    );
  }

  onSignIn() {
    this.router.navigate(['/signin']);
  }

}
