import { Component } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'pin-yin-game';

  constructor(private route: ActivatedRoute) {
    // this.router.events.subscribe((e: any) => {
    //   console.log('Router event:', e);
    // });
    this.route.fragment.subscribe((frament: string) => {
      console.log(frament);
    });
  }
}
