import { Component, OnInit } from '@angular/core';
import { Word } from '../word';
import { WordService } from '../word.service';
import { MessageService } from '../message.service';
import { MatTableDataSource} from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { LoadingDialogComponent } from '../dialog/loading-dialog/loading-dialog.component';

@Component({
  selector: 'app-chapter',
  templateUrl: './chapter.component.html',
  styleUrls: ['./chapter.component.css']
})
export class ChapterComponent implements OnInit {

  selected = '1';
  words: Word[];
  displayedColumns: string[] = ['wid', 'word', 'pinyin', 'audioUrl'];
  hidePY = false;

  ListDataSource: MatTableDataSource<Word>;

  environmentName = 'default';

  constructor(
    private wordService: WordService,
    private messageService: MessageService,
    public dialog: MatDialog) {
  }

  private log(message: string) {
    this.messageService.add(`ChapterComponent: ${message}`);
  }

  ngOnInit() {
    this.getWords();
  }

  getWords(): void {
    let unitID: number;
    unitID = Number.parseInt(this.selected, 10);

    this.log('getWords starts.');
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(LoadingDialogComponent, {disableClose: true});

    this.wordService.queryPYWord(unitID).subscribe(data => {
      this.log('getWords subscribe');
      this.words = data.WORDS;
      this.ListDataSource = new MatTableDataSource(this.words);
      dialogRef.close();
    });
    this.log('getWords ends.');
  }

  hidePinyin(bolValue: boolean) {
    this.hidePY = bolValue;
  }

  playSound(data: string) {
    const audio = new Audio();
    audio.src = 'https://lc.hkbu.edu.hk/pth2500/2500/' + data;
    audio.load();
    audio.play();
  }

  selectUnit() {
    this.log('Select Unit ' + this.selected);
    this.getWords();
  }
}
