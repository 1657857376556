import { Word } from '../word';

export class WordAns {
    wid: number;
    word: Word;
    ansA: string;
    ansB: string;
    ansC: string;
    ansD: string;
    correctAns: string;
}