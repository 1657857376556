import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { MatStep } from '@angular/material/stepper';
import { MessageService } from '../message.service';
import { ShortUrlService } from '../short-url.service';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-short-url',
  templateUrl: './short-url.component.html',
  styleUrls: ['./short-url.component.css']
})
export class ShortUrlComponent implements OnInit {

  longUrl = new FormControl('', [Validators.required, Validators.minLength(6)]);
  shortUrl = new FormControl('', [Validators.required, Validators.minLength(20)]);
  passphrase = new FormControl('', [Validators.required, Validators.minLength(6)]);

  //shortUrl: string;

constructor(private shortUrlService: ShortUrlService, private messageService: MessageService) { 

  }

  ngOnInit() {
  }

  private log(uMessage: string) {
    this.messageService.add(`ShortUrlComponent: ${uMessage}`);
  }

  getUrlErrorMessage() {

    if (this.longUrl.hasError('required')) {
       return 'You must enter an url';
    }

    return '';
  }

  onSubmitCreate(): void {
    // Process checkout data here
    this.log('ConfrimCreate has been submitted');
    this.log('[url] [' + this.longUrl.value + ']');
    this.log('[password] [' + this.passphrase.value + ']');

    this.log('shortenUrl starts.');

    this.shortUrlService.createShortUrl(this.longUrl.value, this.passphrase.value).subscribe(data => {
      this.log('CreateShortUrl subscribe');
      this.log(JSON.stringify(data));
      this.shortUrl.setValue ('https://makya.cc/' + data.shortUrl);
    });
    this.log('shortenUrl ends.');
  }

  testShortUrl() {
    //window.open('G/abcd', '_blank');
    //window.location.href = this.shortUrl.value;
    window.open(this.shortUrl.value, "_blank");
  }

  clear() {
    this.shortUrl.setValue('');
    this.longUrl.setValue('');
  }

  copy() {

    // Copy the text inside the text field
    navigator.clipboard.writeText(this.shortUrl.value);

    // Alert the copied text
    this.log('Copied the text: ' + this.shortUrl.value);

    alert('Short url [' + this.shortUrl.value + '] copied');
  }
}
