import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { interval, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class TimerService {

  progressbarValue = 100;
  curSec: number = 0;
  started: string = 'F';

  constructor() { }

  startTimer(seconds: number) {

    if (this.started === 'T') {
      return;
    }
    
    const timer$ = interval(1000);
    this.started = 'T';

    const sub = timer$.subscribe((sec) => {
      this.progressbarValue = 100 - sec * 100 / seconds;
      this.curSec = sec;

      if (this.curSec === seconds) {
        sub.unsubscribe();
        this.started = 'F';
      }
    });
  }

  getProgressValue(): Observable<number>  {
    return of(this.progressbarValue);
  }

  getCurSec(): Observable<number>  {
    return of(this.curSec);
  }

  getTimerStarted(): Observable<string>  {
    return of(this.started);
  }
}
