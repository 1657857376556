import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { MessageService } from './message.service';

@Injectable({
  providedIn: 'root'
})
export class ShortUrlService {

  private apiShortUrlCreate = 'https://vs0zt71451.execute-api.ap-southeast-1.amazonaws.com/prod';
  private apiShortUrlGet = 'https://7mzbyvwbrd.execute-api.ap-southeast-1.amazonaws.com/prod';

  constructor(private http: HttpClient, private messageService: MessageService ) { 

  }

  private log(message: string) {
    this.messageService.add(`ShortUrlService: ${message}`);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

    // TODO: send the error to remote logging infrastructure
    console.error(error); // log to console instead

    // TODO: better job of transforming error for user consumption
    this.log(`${operation} failed: ${error.message}`);

    // Let the app keep running by returning an empty result.
    return of(result as T);
    };
  }


  createShortUrl(longUrl: string, passphrase: string): Observable<ShortUrlResp> {
    this.log('createShortUrl');
    this.log('api url: ' + this.apiShortUrlCreate);
    this.log('long url: ' + longUrl.toString());
    this.log('password: ' + passphrase.toString());


    return this.http.post<ShortUrlResp>(this.apiShortUrlCreate, { url: longUrl, password: passphrase })
    .pipe(
      tap(_ => this.log('createShortUrl tap')),
      catchError(this.handleError<ShortUrlResp>('createShortUrl Error'))
    );
  }

  getShortUrl(shortUrl: string): Observable<ShortUrlResp> {
    this.log('getShortUrl');
    this.log('api url: ' + this.apiShortUrlGet);
    this.log('short url: ' + shortUrl.toString());

    return this.http.post<ShortUrlResp>(this.apiShortUrlGet, { token: shortUrl })
    .pipe(
      tap(_ => this.log('getShortUrl tap')),
      catchError(this.handleError<ShortUrlResp>('getShortUrl Error'))
    );
  }

}

export interface ShortUrlResp {
  statusCode: number;
  body: string;
  longUrl: string;
  shortUrl: string;
}
