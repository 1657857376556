import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-times-up-dialog',
  templateUrl: './times-up-dialog.component.html',
  styleUrls: ['./times-up-dialog.component.css']
})
export class TimesUpDialogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
