import { Component, OnInit } from '@angular/core';
import { Question } from '../question';
// import { QUESTIONS } from '../mock-questions';
import { QuestionService } from '../question.service';
import { MessageService } from '../message.service';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})
export class QuestionsComponent implements OnInit {

  // questions: Question[];
  qArray: Array<number>;
  questionsAWS: Question[];
  constructor(private questionService: QuestionService, private messageService: MessageService) { }

  private log(message: string) {
    this.messageService.add(`QuestionsComponent: ${message}`);
  }

  ngOnInit() {
    // this.getQuestions();
    this.getQuestionsAWS();
  }

  getQuestionsAWS(): void {
    // this.questionService.getPYQuestionsAWS();
    this.log('getQuestionsAWS starts.');
    this.questionsAWS = this.questionService.getQuestionsAWS();
    this.log('getQuestionsAWS ends.');
  }
}
