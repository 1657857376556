import { Component, OnInit } from '@angular/core';
import { Word } from '../word';
import { WordService } from '../word.service';
import { WordAns } from './myword';
import { MessageService } from '../message.service';
import { MatDialog } from '@angular/material/dialog';
import { LoadingDialogComponent } from '../dialog/loading-dialog/loading-dialog.component';

@Component({
  selector: 'app-generate',
  templateUrl: './generate.component.html',
  styleUrls: ['./generate.component.css']
})
export class GenerateComponent implements OnInit {

  selected: string = '1';
  words: Word[];
  //displayedColumns: string[] = ['id', 'word', 'pinyin'];
  //dataSource: Word[];
  ansArray: Array<WordAns> = [];

  constructor(
    private wordService: WordService,
    private messageService: MessageService,
    public dialog: MatDialog) { }

  private log(message: string) {
    this.messageService.add(`GenerateComponent: ${message}`);
  }

  ngOnInit() {
    
  }

  genAnswer(): void {
    this.log("genAnswer starts.");
    var unitID : number;
    unitID = Number.parseInt(this.selected);

    this.log('getWords starts.');
    this.log('Unit ID: ' + unitID.toString());
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(LoadingDialogComponent, {disableClose: true});

    this.wordService.queryPYWord(unitID).subscribe(data => {
      this.log('getWords subscribe');
      //this.log(JSON.stringify(data));
      this.words = data.WORDS;
      this.genWordAnsArray(data.WORDS);
      this.setRandomAnswers();
      dialogRef.close();
    });
    
    this.log('genAnswer ends.');
  }

  genWordAnsArray(data: Word[]): void {

    this.log('genWordAnsArray starts');
    this.ansArray.length = 0;

    // this.log(JSON.stringify(data));
    for (const w of data) {
      const wd = new WordAns();
      wd.word = w;
      wd.wid = w.wid;
      wd.ansA = 'ansA';
      wd.ansB = 'ansB';
      wd.ansC = 'ansC';
      wd.ansD = 'ansD';

      this.ansArray.push(wd);
    }
    // this.log('this.ansArray: ' + JSON.stringify(this.ansArray));
    this.log('genWordAnsArray ends');
  }

  setRandomAnswers(): void {

    this.log('setRandomAnswers starts.');
    let currentIndex: number;
    currentIndex = this.ansArray.length;
    // this.log('current index: ' + currentIndex.toString());

    while (0 !== currentIndex) {
      currentIndex -= 1;
      // this.log('current index of random answer is ' + currentIndex.toString());
      this.ansArray[currentIndex].ansA = this.getUniqueAns(this.ansArray[currentIndex]);
      this.ansArray[currentIndex].ansB = this.getUniqueAns(this.ansArray[currentIndex]);
      this.ansArray[currentIndex].ansC = this.getUniqueAns(this.ansArray[currentIndex]);
      this.ansArray[currentIndex].ansD = this.getUniqueAns(this.ansArray[currentIndex]);
      this.setCorrectAnswer(this.ansArray[currentIndex]);
    }

    this.log('setRandomAnswers ends.');
  }

  getUniqueAns(data: WordAns): string {

    // this.log('getUniqueAns starts.');

    var isValid = false;
    var tempAns: string;
    var searchString: string;

    // searchString contains word.pinyin and answer pinyin.
    searchString = '[' + data.word.pinyin + ']';
    searchString = searchString + '[' + data.ansA + ']';
    searchString = searchString + '[' + data.ansB + ']';
    searchString = searchString + '[' + data.ansC + ']';
    searchString = searchString + '[' + data.ansD + ']';
    // this.log(searchString);
    var index = 0;
    var keyString: string;
    // var count = 0;

    while (!isValid) {
      // get one word.pinyin randomly
      tempAns = this.getRandomAns();
      keyString = '[' + tempAns + ']';
      //this.log('keyString is ' + keyString);
      // if ramdon pinyin is same as word.pinyin or other answer pinyin. 
      // search again.
      index = searchString.indexOf(keyString);
      //this.log('index is ' + index.toString());
      if (index === -1 ) {
          isValid = true;
          return tempAns;
      }
    }
    return 'false';

    //this.log('getUniqueAns ends.');
  }

  getRandomAns(): string {
    var randomIndex: number;

    if (this.ansArray.length > 1) {
        randomIndex = Math.floor(Math.random() * this.ansArray.length);
        return this.ansArray[randomIndex].word.pinyin;
    }

    return '';
  }

  setCorrectAnswer(myWord: WordAns) {

    var randomIndex: number;

    randomIndex = Math.ceil(Math.random() * 4);

    switch (randomIndex) {
    case 1:
        myWord.correctAns = 'A'
        myWord.ansA = myWord.word.pinyin;
        break;
    case 2:
        myWord.correctAns = 'B'
        myWord.ansB = myWord.word.pinyin;
        break;
    case 3:
        myWord.correctAns = 'C'
        myWord.ansC = myWord.word.pinyin;
        break;
    case 4:
        myWord.correctAns = 'D'
        myWord.ansD = myWord.word.pinyin;
        break;
    default:
        myWord.correctAns = '';
        break;
    }
  }
}
