import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent} from './home/home.component';
import { QuestionDetailComponent } from './question-detail/question-detail.component';
import { ChapterComponent } from './chapter/chapter.component';
import { ResultComponent } from './result/result.component';
import { GenerateComponent } from './generate/generate.component';
import { QuestionsComponent } from './questions/questions.component';
import { SignupComponent } from './account/signup/signup.component';
import { SigninComponent } from './account/signin/signin.component';
import { ResetPwdComponent } from './account/reset-pwd/reset-pwd.component';
import { ChgPwdComponent } from './account/chg-pwd/chg-pwd.component';
import { ProfileComponent } from './account/profile/profile.component';
import { ShortUrlComponent } from './short-url/short-url.component';
import { RedirectUrlComponent } from './redirect-url/redirect-url.component';
import { GoUrlComponent } from './go-url/go-url.component';

const routes: Routes = [
  { path: '', redirectTo: '/shorturl', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'question/:id', component: QuestionDetailComponent, runGuardsAndResolvers: 'always' },
  { path: 'chapter', component: ChapterComponent},
  { path: 'result', component: ResultComponent},
  { path: 'generate', component: GenerateComponent},
  { path: 'questions', component: QuestionsComponent},
  { path: 'signup', component: SignupComponent},
  { path: 'signin', component: SigninComponent},
  { path: 'resetpwd', component: ResetPwdComponent},
  { path: 'chgpwd', component: ChgPwdComponent},
  { path: 'profile', component: ProfileComponent},
  { path: 'shorturl', component: ShortUrlComponent},
  { path: 'redirecturl', component: RedirectUrlComponent},
  { path: 'G/:shorturl', component: GoUrlComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload', enableTracing: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
