import { Component, OnInit } from '@angular/core';
import { QuestionService } from '../question.service';
import { MessageService } from '../message.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { LoadingDialogComponent } from '../dialog/loading-dialog/loading-dialog.component';
import { ProfileService } from '../profile.service';
import { QuizResultService } from '../quiz-result.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css', '../question-detail/question-detail.component.css']
})
export class HomeComponent implements OnInit {

  selected = '1';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private questionService: QuestionService,
    private messageService: MessageService,
    public dialog: MatDialog,
    private profileService: ProfileService,
    private quizResultService: QuizResultService) { }

  private log(message: string) {
    this.messageService.add(`home component: ${message}`);
  }

  ngOnInit() {

  }

  callStart(): void {

    this.log('callStart starts.');
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(LoadingDialogComponent, {disableClose: true});

    this.log('selected unit: ' + this.selected);
    this.questionService.resetAns();
    this.questionService.queryPYQuesions(Number(this.selected)).subscribe(data => {
        this.log('getQuestionsAWS subscribe');
        // this.log(JSON.stringify(data));
        this.questionService.setQuestionsAWS(data.QUESTIONS);
        this.goQuestionPage();
        dialogRef.close();
        // this.questionsAWS = data.QUESTIONS;
        // this.log(JSON.stringify(this.questionsAWS));
    });

    if (this.profileService.isUserLogined) {
      this.quizResultService.startQuiz( this.profileService.getUserID(), Number(this.selected));
    }
  }

  goQuestionPage() {
    this.router.navigate(['/question/1'], { relativeTo: this.route });
  }
}
