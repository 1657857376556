import { Injectable } from '@angular/core';
import { Question } from './question';
//import { QUESTIONS } from './mock-questions';
import { Observable, of } from 'rxjs';
import { MessageService } from './message.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class QuestionService {

  awsQuestion: Question[] = [];
  viewedCount: number = 0;
  totalCount: number = 0;
  //questionsArray: Array<number> = [];  // a question array holding question id in random order

  //private pyQueryQuestionUrl = 'https://ssdusmz9qa.execute-api.ap-southeast-1.amazonaws.com/dev';
  // private pyQueryQuestionUrl = 'https://ssdusmz9qa.execute-api.ap-southeast-1.amazonaws.com/prod';
  private pyQueryQuestionUrl = '';

  constructor(private http: HttpClient, private messageService: MessageService) {
    this.pyQueryQuestionUrl = environment.apiUrl_QuestionGet;
  }

  private log(message: string) {
    this.messageService.add(`QuestionService: ${message}`);
  }

  // getPYQuestionsAWS(): void {

  //   this.log('getPYQuestionsAWS');
  //   this.http.post<QuestionResp>(this.pyQueryQuestionUrl, { wid: 2 }).subscribe(data => {
  //       this.awsQuestion = data.QUESTIONS;
  //       this.log(('Returned value is ' + JSON.stringify(data.QUESTIONS)));
  //   });

  //   this.log('getPYQuestionsAWS end');
  // }

  setQuestionsAWS(data: Question[]) {
    this.log('setQuestionsAWS');
    this.awsQuestion = data;
    this.totalCount = this.awsQuestion.length;
    this.shuffle(this.awsQuestion);
  }

  getQuestionsAWS(): Question[] {
    return this.awsQuestion;
  }

  queryPYQuesions(id: number): Observable<QuestionResp> {
    this.log('queryPYQuesions');
    this.log('unitID: ' + id.toString());
    return this.http.post<QuestionResp>(this.pyQueryQuestionUrl, { unitID: id })
    .pipe(
      tap(_ => this.log('queryPYQuesions tap')),
      catchError(this.handleError<QuestionResp>('queryPYQuesions Error'))
    )
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

    // TODO: send the error to remote logging infrastructure
    console.error(error); // log to console instead

    // TODO: better job of transforming error for user consumption
    this.log(`${operation} failed: ${error.message}`);

    // Let the app keep running by returning an empty result.
    return of(result as T);
    };
  }

  

  // getQuestions(): Observable<Question[]> {
  //   this.totalCount = QUESTIONS.length;
  //   return of(QUESTIONS);
  // }

  // getQuestion(id: number): Observable<Question> {
  //   // TODO: send the message _after_ fetching the hero
  //   return of(QUESTIONS.find(question => question.id === id));
  // }

  // getQuestionByPos(pos: number): Observable<Question> {
  //   //return of(QUESTIONS[pos]);

  //   const qid = this.questionsArray[pos-1];
  //   return of(QUESTIONS.find(question => question.id === qid));
  // }

  getQuestionByPosAWS(pos: number): Question {
    return this.awsQuestion[pos-1];
  }

  shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  } 

  resetAns(): void {

    // for (const q of QUESTIONS) {
    //   q.selectedAns = '';
    //   q.selectedPinYin = '';
    //   q.correct = 'false1';
    //   this.questionsArray.push(q.id);
    // }

    // this.shuffle(this.questionsArray);
    //this.log("resetAns() set view count to zero.");
    this.viewedCount = 0;
    this.totalCount = 0;
    this.awsQuestion = [];
  }

  setViewedCount(count: number): void {
    if ((count - this.viewedCount) === 1) {
      this.viewedCount += 1;
    }
  }

  getViewedQuestionsAWS(): Question[] {

    var viewedQuestions: Array<Question> = [];
    var currentIndex = 0;

    this.log("Viewed Count :"+this.viewedCount.toString());

    while (currentIndex < this.viewedCount) {
      viewedQuestions.push(this.awsQuestion[currentIndex]);
      currentIndex += 1;
    }

    return viewedQuestions;
  }
}

export interface QuestionResp {
  statusCode: number;
  body: string;
  QUESTIONS: Question[];
}
