import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../profile.service';
import { Router, NavigationEnd } from '@angular/router';
import { MessageService } from '../message.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit {

  isSignedIn = false;
  isQuizMode = false;
  navigationSubscription;

  environmentName = 'default';

  constructor(
    private router: Router,
    private profileService: ProfileService,
    private messageService: MessageService) {
       this.environmentName = environment.environmentName;
    }

  private log(uMessage: string) {
    this.messageService.add(`ToolbarComponent: ${uMessage}`);
  }

  ngOnInit() {
    this.checkLogin();

    // subscribe to the router events - storing the subscription so
    // we can unsubscribe later.
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.initialiseInvites();
      }
    });
  }

  initialiseInvites() {
    this.checkLogin();
  }

  checkLogin() {
    // skipped for makya.cc No need to verify login
    return;

    // Set default values and re-fetch any data you need.
    this.profileService.isUserLogined().then(res => {
        // this.log('isUserLoged Result: ' + JSON.stringify(res));
        this.isSignedIn = res;
        }
    );
  }

  routerGo(tag: string) {

    console.log('routerGo:' + JSON.stringify(tag));
    switch (tag) {
        case 'home':
          this.router.navigate(['/home']);
          break;
        case 'profile':
          this.router.navigate(['/profile']);
          break;
        case 'signin':
          this.router.navigate(['/signin']);
          break;
        case 'signup':
          this.router.navigate(['/signup']);
          break;
        case 'signout':
          this.profileService.signOut().then(res => {
                if (res) {
                  this.router.navigate(['/home']);
                }
              }
          );
          break;
        case 'chgpwd':
          this.router.navigate(['/chgpwd']);
          break;
        case 'words':
          this.router.navigate(['/chapter']);
          break;
        case 'shorturl':
          this.router.navigate(['/shorturl']);
          break;
        case 'redirecturl':
          this.router.navigate(['/redirecturl']);
          break;
    }
  }
}
