import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { MessageService } from './message.service';
import { r3JitTypeSourceSpan } from '@angular/compiler';
// import { JSDocTagName } from '@angular/compiler/src/output/output_ast';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  errMsg: string;
  userEmail: string;
  userNickname: string;
  userIcon: string;
  userID: string;
  tokenID: string;
  userObj;

  // private pyProfileUrl = 'https://9d0few87ai.execute-api.ap-southeast-1.amazonaws.com/dev';
  // private pyProfileUpdateUrl = 'https://9zlpdce3rf.execute-api.ap-southeast-1.amazonaws.com/dev';
  // private pyProfileAddUrl = 'https://7wv5tkvkx8.execute-api.ap-southeast-1.amazonaws.com/dev';
  
  private pyProfileUrl = '';
  private pyProfileUpdateUrl = '';
  private pyProfileAddUrl = '';

  //private pyProfileUrl = 'https://9d0few87ai.execute-api.ap-southeast-1.amazonaws.com/prod';
  //private pyProfileUpdateUrl = 'https://9zlpdce3rf.execute-api.ap-southeast-1.amazonaws.com/prod';
  //private pyProfileAddUrl = 'https://7wv5tkvkx8.execute-api.ap-southeast-1.amazonaws.com/prod';

  constructor(private http: HttpClient, private messageService: MessageService ) {

    this.pyProfileUrl = environment.apiUrl_ProfileGet;
    this.pyProfileUpdateUrl = environment.apiUrl_ProfileUpdate;
    this.pyProfileAddUrl = environment.apiUrl_ProfileAdd;
  }

  private log(uMessage: string) {
    this.messageService.add(`ProfileService: ${uMessage}`);
  }

  getUserEmail(): string {
    return this.userEmail;
  }

  getUserIcon(): string {
    return this.userIcon;
  }

  getUserNickname(): string {
    return this.userNickname;
  }

  getErrMsg(): string {
    return this.errMsg;
  }

  getTokenID(): string {
    return this.tokenID;
  }

  getUserID(): string {
    return this.userID;
  }

  async signUp(uEmail: string, uPassword: string) {
    this.log('signUp starts.');
    try {
      await Auth.signUp({
        username: uEmail,
        password: uPassword,
        attributes: {
          email: uEmail
        }
      });
      this.log('signUp success.');
      return true;
    } catch (error) {
      this.log('singUp error: ' + error.message);
      this.errMsg = error.message;
      return false;
    }
  }

  async confirmSignUp(uEmail: string, uCode: string) {
    this.log('confirmSignUp starts.');
    try {
      await Auth.confirmSignUp(uEmail, uCode);
      this.log('confirmSignUp success.');
      return true;
    } catch (error) {
      this.log('confirmSignUp error: ' + error.message);
      this.errMsg = error.message;
      return false;
    }
  }

  async resendCode(uEmail: string) {
    this.log('resendCode starts.');
    try {
      await Auth.resendSignUp(uEmail);
      this.log('resendCode success.');
      return true;
    } catch (error) {
      this.log('resendCode error: ' + error.message);
      this.errMsg = error.message;
      return false;
    }
  }

  async sendPwdCode(uEmail: string) {
    this.log('sendPwdCode starts.');
    try {
      await Auth.forgotPassword(uEmail);
      this.log('sendPwdCode success.');
      return true;
    } catch (error) {
      this.log('error sendPwdCode' + error.message);
      this.errMsg = error.message;
      return false;
    }
  }

  async resetPwd(uEmail: string, uCode: string, uNewPwd: string ) {
    this.log('resetPwd starts.');
    try {
      await Auth.forgotPasswordSubmit(uEmail, uCode, uNewPwd);
      this.log('resetPwd success.');
      return true;
    } catch (error) {
      this.log('resetPwd error: ' + error.message);
      this.errMsg = error.message;
      return false;
    }
  }

  async changePwd(uOldPwd: string, uNewPwd: string ) {
    this.log('changePwd starts.');
    try {
      this.log('user ' + this.userObj);
      this.log('old password ' + uOldPwd);
      this.log('new password ' + uNewPwd);
      await Auth.changePassword(this.userObj, uOldPwd, uNewPwd);
      this.log('changePwd success.');
      return true;
    } catch (error) {
      this.log('changePwd error: ' + error.message);
      this.errMsg = error.message;
      return false;
    }
  }

  async signIn(uEmail: string, uPwd: string) {
    this.log('signIn starts.');
    try {
      const user = await Auth.signIn(uEmail, uPwd);
      this.log('signin success.');
      // console.log('after signIn userobj ' + JSON.stringify(user));
      this.userID = user.username;
      this.log('after signIn' + JSON.stringify(this.userID));
      return true;
    } catch (error) {
      this.log('signIn error: ' + error.message);
      this.errMsg = error.message;
      return false;
    }
  }

  async signOut() {
    this.log('signOut starts.');
    try {
        await Auth.signOut();
        this.log('signOut success.');
        return true;
    } catch (error) {
        this.log('signOut Error: ' + error.message);
        this.errMsg = error.message;
        return false;
    }
  }

  async refreshTokenID() {

     try {

      const sesObj = await Auth.currentSession();
      // console.log('getTokenID: ' + JSON.stringify(sesObj));
      // let accessToken = sesObj.getAccessToken()
      // let jwt = accessToken.getJwtToken()
      const tID = sesObj.getIdToken();
      this.tokenID = tID.getJwtToken();
      // You can print them to see the full objects
      // console.log(`myTokenID: ${JSON.stringify(this.tokenID)}`);
    } catch (error) {
        this.log('isUserLogined Not login: ' + error.message);
        // return 0;
    }

  }
  async isUserLogined() {
    // this.log('isUserLogined starts.');
    try {
        const user = await Auth.currentAuthenticatedUser();
        // this is not function flow and data is too long to display in message box.
        // message out in console.log
        // console.log('user:' + JSON.stringify(user));
        console.log('user username: ' + user.username);
        console.log('user attributes: ' + user.attributes);
        // tslint:disable-next-line: no-string-literal
        console.log('user email address: ' + user.attributes['email']);
        this.userID = user.username;

        // tslint:disable-next-line: no-string-literal
        this.userEmail = user.attributes['email'];
        this.userObj = user;
        this.queryProfile(this.userID);
        // this.log('isUserLogined success.');
        return true;
    } catch (error) {
        this.log('isUserLogined Not login: ' + error.message);
        return false;
    }
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

    // TODO: send the error to remote logging infrastructure
    console.error(error); // log to console instead

    // TODO: better job of transforming error for user consumption
    // Let the app keep running by returning an empty result.
    return of(result as T);
    };
  }

  queryPYProfile(uData: string): Observable<ProfileResp> {
    // token id
    const hdr = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.tokenID });
    const options = { headers: hdr };

    console.log('getHttpOption: ' + JSON.stringify(options));

    // production db key is username
    // return this.http.post<ProfileResp>(this.pyProfileUrl, { username: uData }, options)
    // db key is userid
    console.log('uData:' + JSON.stringify(uData));
    console.log('post url: ' + this.pyProfileUrl);
    console.log('post data: ' + uData.toString());
    return this.http.post<ProfileResp>(this.pyProfileUrl, { userid: uData }, options)
    .pipe(
      tap(_ => console.log('queryPYProfile tap')),
      catchError(this.handleError<ProfileResp>('queryPYProfile Error'))
    );
  }

  async queryProfile(uData: string) {
    //this.log('queryProfile starts');

    await this.refreshTokenID();

    this.queryPYProfile(uData).subscribe(res => {
      this.userNickname = res.PROFILE.nickname;
      this.userIcon = res.PROFILE.icon;
    });
  }

  updatePYProfile(uID: string, uName: string, uIconname: string): Observable<ProfileResp> {
    this.log('updatePYProfile starts');

    // token id
    const hdr = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.tokenID });
    const options = { headers: hdr };

    // production version
    //return this.http.post<ProfileResp>(this.pyProfileUpdateUrl, { username: uID, nickname: uName, icon: uIconname }, options )

    // dev version
    return this.http.post<ProfileResp>(this.pyProfileUpdateUrl, { userid: uID, nickname: uName, icon: uIconname }, options )
    .pipe(
      tap(_ => console.log('updatePYProfile tap')),
      catchError(this.handleError<ProfileResp>('updatePYProfile Error'))
    );
  }

  async updateProfile( uID: string, uName: string, uIcon: string ) {
    this.log('updateProfile starts.');

    await this.refreshTokenID();

    this.updatePYProfile(uID, uName, uIcon).subscribe(res => {
      // console.log('updateProfile: ' + JSON.stringify(res));
      // console.log('updateProfile: ' + this.getUserNickname() + '|' + this.getUserIcon());
      this.userNickname = uName;
      this.userID = uIcon;
    });

    this.log('updateProfile ends.');
  }

  addPYProfile(uID: string, uEmail: string): Observable<ProfileResp> {

    this.log('addPYProfile starts.');
    // this.log('addProfile username[' + uid + '] , nickname[' + name + ']');
    const hdr = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.tokenID });
    const options = { headers: hdr };

    this.log('username: ' + uID);

    return this.http.post<ProfileResp>(this.pyProfileAddUrl, { username: uID, userEmail: uEmail}, options )
    .pipe(
      tap(_ => console.log('addPYProfile tap')),
      catchError(this.handleError<ProfileResp>('addPYProfile Error'))
    );
  }

  async addProfile( uID: string, uEmail: string) {
    this.log('addProfile starts.');
    // this.log('addProfile username[' + uid + '] , nickname[' + name + ']');

    this.addPYProfile(uID, uEmail).subscribe(res => {
      console.log('addPYProfile complete' + JSON.stringify(res));
      // this.userNickname = res.PROFILE.nickname;
      // this.userIcon = res.PROFILE.icon;
    });

    this.log('addProfile ends.');
  }
}

export interface ProfileResp {
  statusCode: number;
  body: string;
  PROFILE: Profile;
}

export interface Profile {
  username: string;
  nickname: string;
  icon: string;
}
