import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exit-dialog',
  templateUrl: './exit-dialog.component.html',
  styleUrls: ['./exit-dialog.component.css']
})
export class ExitDialogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
