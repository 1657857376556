import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

// Material components
import { MatTableModule} from '@angular/material/table';
import { MatCardModule} from '@angular/material/card';
import { MatRadioModule} from '@angular/material/radio';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonToggleModule} from '@angular/material/button-toggle';
import { MatPaginatorModule} from '@angular/material/paginator';
import { MatSnackBarModule} from '@angular/material/snack-bar';
import { MatDialogModule} from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule} from '@angular/material/toolbar';
import { MatIconModule} from '@angular/material/icon';
import { MatButtonModule} from '@angular/material/button';
import { MatGridListModule} from '@angular/material/grid-list';
import { MatSelectModule} from '@angular/material/select';
import { MatDividerModule} from '@angular/material/divider';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule} from '@angular/material/form-field';

/* Add Amplify imports */
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import Amplify from 'aws-amplify';
import awsconfig from '../aws-exports';

// App Components
import { AppComponent } from './app.component';
import { QuestionDetailComponent } from './question-detail/question-detail.component';
import { QuestionsComponent } from './questions/questions.component';
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { ChapterComponent} from './chapter/chapter.component';
import { ResultComponent } from './result/result.component';
import { GenerateComponent } from './generate/generate.component';
import { MessagesComponent } from './messages/messages.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { SignupComponent } from './account/signup/signup.component';
import { SigninComponent } from './account/signin/signin.component';
import { ResetPwdComponent } from './account/reset-pwd/reset-pwd.component';
import { ChgPwdComponent } from './account/chg-pwd/chg-pwd.component';
import { ProfileComponent } from './account/profile/profile.component';
import { EditDialogComponent } from './dialog/edit-dialog/edit-dialog.component';
import { ExitDialogComponent } from './dialog/exit-dialog/exit-dialog.component';
import { TimesUpDialogComponent } from './dialog/times-up-dialog/times-up-dialog.component';
import { LoadingDialogComponent } from './dialog/loading-dialog/loading-dialog.component';
import { ShortUrlComponent } from './short-url/short-url.component';
import { RedirectUrlComponent } from './redirect-url/redirect-url.component';
import { GoUrlComponent } from './go-url/go-url.component';

/* Configure Amplify resources */
Amplify.configure(awsconfig);

@NgModule({
  declarations: [
    AppComponent,
    QuestionDetailComponent,
    QuestionsComponent,
    HomeComponent,
    ChapterComponent,
    ResultComponent,
    GenerateComponent,
    MessagesComponent,
    ToolbarComponent,
    SignupComponent,
    SigninComponent,
    ResetPwdComponent,
    ChgPwdComponent,
    ProfileComponent,
    EditDialogComponent,
    ExitDialogComponent,
    TimesUpDialogComponent,
    LoadingDialogComponent,
    ShortUrlComponent,
    RedirectUrlComponent,
    GoUrlComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AmplifyUIAngularModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatGridListModule,
    MatSelectModule,
    MatDividerModule,
    MatCheckboxModule,
    MatTableModule,
    MatCardModule,
    MatRadioModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatDialogModule,
    MatListModule,
    MatInputModule,
    MatMenuModule,
    MatStepperModule,
    MatFormFieldModule
  ],
  entryComponents: [LoadingDialogComponent, ExitDialogComponent, EditDialogComponent, TimesUpDialogComponent],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
