import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ProfileService } from '../../profile.service';
import { MatStepper, MatStep} from '@angular/material/stepper';
import { MessageService } from '../../message.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {

  email = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('', [Validators.required, Validators.minLength(6)]);
  validLogin: boolean;
  errMsgLogin: string;

  constructor(private router: Router, private profileService: ProfileService, private messageService: MessageService) { }

  private log(uMessage: string) {
    this.messageService.add(`SigninComponent: ${uMessage}`);
  }

  ngOnInit() {
  }

  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter a value';
    }

    return this.email.hasError('email') ? 'Not a valid email' : '';
  }

  onSubmitLogin(uStepper: MatStepper, uStep: MatStep) {
    this.log('onSubmitLogin starts.');

    this.profileService.signIn(this.email.value, this.password.value).then(res => {
        console.log(JSON.stringify(res));
        if (res) {
          this.log ('login success.');
          // add profile if id not exist (first login)
          console.log('after login success, userID :' + this.profileService.getUserID());
          this.profileService.addProfile(this.profileService.getUserID(), this.email.value);

          uStep.completed = true;
          uStepper.next();
          setTimeout(() => {
              this.router.navigate(['/home']);
          }, 3000);  // 3s
        } else {
          this.errMsgLogin = this.profileService.getErrMsg();
          this.validLogin = false;
          this.log('signIn failed: ' + this.errMsgLogin);
        }
      }
    );
  }

  onSignUp() {
    this.router.navigate(['/signup']);
  }

  onResetPwd() {
    this.router.navigate(['/resetpwd']);
  }
}
