import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Question } from '../question';
import { QuestionService } from '../question.service';
import { Router, NavigationEnd } from '@angular/router';
import { TimerService } from '../timer.service';
import { MatDialog } from '@angular/material/dialog';
import { interval, Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageService } from '../message.service';
import { ExitDialogComponent } from '../dialog/exit-dialog/exit-dialog.component';
import { TimesUpDialogComponent } from '../dialog/times-up-dialog/times-up-dialog.component';
import { QuizResultService } from '../quiz-result.service';

@Component({
  selector: 'app-question-detail',
  templateUrl: './question-detail.component.html',
  styleUrls: ['./question-detail.component.css']
})

export class QuestionDetailComponent implements OnInit {

  question: Question;
  navigationSubscription;
  atStart = false;
  atEnd = false;
  totalCount = 10;

  private subscription;
  progressbarValue = 100;
  curSec = 0;
  gameSec = 180;      // 3 min
  remainSec = 60;     // 60 seconds alert
  started = 'F';

  selectedAnswer: string;
  public nextID: number;
  public questionPos: number;
  curSequenceID: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private questionService: QuestionService,
    public timerService: TimerService,
    private location: Location,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private messageService: MessageService,
    private quizResultService: QuizResultService
  ) {

    // subscribe to the router events - storing the subscription so
    // we can unsubscribe later.
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.initialiseInvites();
      }
    });
  }

  private log(message: string) {
    this.messageService.add(`QuestionDetailComponent: ${message}`);
  }

  initialiseInvites() {
    // Set default values and re-fetch any data you need.
    // this.getQuesiton();
    this.getQuesitonAWS();
    // this.createRadioAnswer(this.question);
    this.selectedAnswer = this.question.selectedAns;
  }

  ngOnInit() {

    this.getQuesitonAWS();
    this.startTimer(this.gameSec);
  }

  getQuesitonAWS(): void {

    const id = +this.route.snapshot.paramMap.get('id');
    this.curSequenceID = id;
    // this.questionService.getQuestion(id).subscribe(question => this.question = question);
    // get question via question array
    // this.questionService.getQuestionByPos(id).subscribe(question => this.question = question);
    this.question = this.questionService.getQuestionByPosAWS(id);

    this.atStart = (id === 1);
    this.atEnd = (id === this.questionService.totalCount);
    this.log('question details get question set viewed count :' + id.toString());
    this.questionService.setViewedCount(id);
  }

  playSound() {
    const audio = new Audio();
    audio.src = 'https://lc.hkbu.edu.hk/pth2500/2500/' + this.question.audioUrl;
    audio.load();
    audio.play();
  }

  selectAns(value: string) {
    this.question.selectedAns = value;

    switch (value) {
    case 'A':
        this.question.selectedPinYin = this.question.ansA;
        break;
    case 'B':
        this.question.selectedPinYin = this.question.ansB;
        break;
    case 'C':
        this.question.selectedPinYin = this.question.ansC;
        break;
    case 'D':
        this.question.selectedPinYin = this.question.ansD;
        break;
    default:
        this.question.selectedPinYin = '';
        break;
    }

    if (value === this.question.correctAns) {
      this.question.correct = 'true';
    } else {
      this.question.correct = 'false';
    }
  }

  showExitDialog() {
    const dialogRef = this.dialog.open(ExitDialogComponent);

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.goResultPage();
      }
      console.log(`Dialog result: ${result}`);
    });
  }

  goResultPage() {
    this.stopTimer();
    this.quizResultService.setQuizTime(this.curSec);
    this.router.navigate(['/result'], { relativeTo: this.route });
  }

  startTimer(seconds: number) {

    if (this.started === 'T') {
      return;
    }

    const timer$ = interval(1000);
    this.started = 'T';

    const alertSec = this.gameSec - this.remainSec;

    this.subscription = timer$.subscribe((sec) => {
      this.progressbarValue = 100 - sec * 100 / seconds;
      this.curSec = sec;

      if ((this.curSec === alertSec) && (this.started === 'T')) {
        this.openSnackBar();
      }

      if (this.curSec === seconds) {
        this.subscription.unsubscribe();
        this.started = 'C';

        this.showTimesUpDialog();
      }
    });
  }

  stopTimer() {
      this.subscription.unsubscribe();
      this.started = 'C';
  }

  openSnackBar() {
    const msg = this.remainSec.toString() + ' seconds remaining!';

    this.snackBar.open(msg, '', {
      duration: 2000,
    }); // 2 seconds
  }

  showTimesUpDialog() {

    this.dialog.closeAll();

    const dialogRef = this.dialog.open(TimesUpDialogComponent, {disableClose: true});

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.goResultPage();
      }
      console.log(`Dialog result: ${result}`);
    });
  }
}

/* @Component({
  selector: 'exit-dialog',
  templateUrl: 'exit-dialog.html',
})
export class ExitDialog {
} */

// @Component({
//   selector: 'times-up-dialog',
//   templateUrl: 'times-up-dialog.html',
// })
// export class TimesUpDialog {
// }

