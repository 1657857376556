import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from '../message.service';
import { ShortUrlService } from '../short-url.service';

@Component({
  selector: 'app-redirect-url',
  templateUrl: './redirect-url.component.html',
  styleUrls: ['./redirect-url.component.css']
})
export class RedirectUrlComponent implements OnInit {

  //shortUrl: string;
  //longUrl: string;
  longUrl = new FormControl('', [Validators.required, Validators.minLength(4)]);
  shortUrl = new FormControl('', [Validators.required, Validators.minLength(4)]);

  constructor(private route: ActivatedRoute, private shortUrlService: ShortUrlService, private messageService: MessageService) { 

  }

  ngOnInit() {
    
  }

  private log(uMessage: string) {
    this.messageService.add(`RedirectUrlComponent: ${uMessage}`);
  }


  get() {
    this.log('get from [shorturl]:[' + this.shortUrl.value + ']')

    this.log('Get Url starts.');

    this.shortUrlService.getShortUrl(this.shortUrl.value).subscribe(data => {
      this.log('GetShortUrl subscribe');
      this.log(JSON.stringify(data));
      this.longUrl.setValue(data.longUrl);
    });
    this.log('Get Url ends.');

  }

  go() {
    this.log('go to [longurl]:[' + this.longUrl + ']')

    //window.location.href = this.longUrl;
    window.open(this.longUrl.value, "_blank");

  }

  clear() {
    this.shortUrl.setValue('');
    this.longUrl.setValue('');
  }

  copy() {

    // Copy the text inside the text field
    navigator.clipboard.writeText(this.longUrl.value);

    // Alert the copied text
    this.log('Copied the text: ' + this.longUrl.value);

    alert('Long url [' + this.longUrl.value + '] copied');
  }

}
