import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../profile.service';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from '../../message.service';
import { EditDialogComponent } from '../../dialog/edit-dialog/edit-dialog.component';
import { QuizResultService, UnitResult } from '../../quiz-result.service';
import { MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})

export class ProfileComponent implements OnInit {


  username: string;  // username id
  userEmail: string;
  nickname: string;
  usericon: string;

  unitResults: UnitResult[];
  displayedColumns: string[] = ['unit', 'time', 'marks', 'date' ];
  ListDataSource: MatTableDataSource<UnitResult>;

  getTotalScore() {
    if (this.unitResults !== undefined ) {
      return this.unitResults.map(t => t.correct).reduce((acc, value) => acc + value, 0);
    } else {
      return 0;
    }
  }

  getTotalSec() {
    if (this.unitResults !== undefined ) {
      return this.unitResults.map(t => t.seconds).reduce((acc, value) => acc + value, 0);
    } else {
      return  0;
    }
  }

  getFormattedDate(uData: string) {
    return uData.substr(0, 10);
  }

  constructor(
    public dialog: MatDialog,
    private profileService: ProfileService,
    private messageService: MessageService,
    private quizResultService: QuizResultService) { }

  private log(uMessage: string) {
    this.messageService.add(`ProfileComponent: ${uMessage}`);
  }



  ngOnInit() {
    this.username = this.profileService.getUserID();
    this.userEmail = this.profileService.getUserEmail();
    this.usericon = this.profileService.getUserIcon();
    this.nickname = this.profileService.getUserNickname();

    this.log('username (full id): ' + this.username);

    this.quizResultService.queryPYTranscript(this.username).subscribe( data => {
      this.unitResults = data.RESULTS;
      console.log(JSON.stringify(this.unitResults));
      this.ListDataSource = new MatTableDataSource(this.unitResults);
    });
  }

  showEditDialog() {

    const dialogRef = this.dialog.open(EditDialogComponent, {
      data: {iconname: this.usericon, nickname: this.nickname}
    });

    dialogRef.afterClosed().subscribe(result => {

      this.log(`Dialog result: ${result}`);

      // result is not empty
      if (result) {
        this.nickname = result.nickname;
        this.usericon = result.iconname;

        console.log('userProfile: ' + JSON.stringify(this.username, result.nickname, result.iconname));

        this.profileService.updateProfile(this.username, result.nickname, result.iconname);
      }
    });
  }
}
