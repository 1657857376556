import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MessageService } from './message.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Word } from './word';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class WordService {

  totalCount: number = 0;
  tempID: number;
  tempStr: string;

  // private wordUrl = 'https://hmgzk1ryhi.execute-api.ap-southeast-1.amazonaws.com/dev';
  // private pyWordUrl = 'https://tcsjia2kkj.execute-api.ap-southeast-1.amazonaws.com/dev';
  // private pyQueryWordUrl = 'https://9u5jj9qn3c.execute-api.ap-southeast-1.amazonaws.com/dev';
  // private pyQueryWordUrl = 'https://9u5jj9qn3c.execute-api.ap-southeast-1.amazonaws.com/prod';

  private pyQueryWordUrl = '';

  constructor( private http: HttpClient, private messageService: MessageService) {
    this.pyQueryWordUrl = environment.apiUrl_WordGet;
  }

  private log(message: string) {
    this.messageService.add(`WordService: ${message}`);
  }

  queryPYWord(id: number): Observable<WordResp> {
    this.log('queryPYWord');
    this.log('query url: ' + this.pyQueryWordUrl);
    this.log('unit id: ' + id.toString());
    return this.http.post<WordResp>(this.pyQueryWordUrl, { unitID: id })
    .pipe(
      tap(_ => this.log('queryPYWord tap')),
      catchError(this.handleError<WordResp>('queryPYWord Error'))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

    // TODO: send the error to remote logging infrastructure
    console.error(error); // log to console instead

    // TODO: better job of transforming error for user consumption
    this.log(`${operation} failed: ${error.message}`);

    // Let the app keep running by returning an empty result.
    return of(result as T);
    };
  }
}

export interface WordResp {
  statusCode: number;
  body: string;
  WORDS: Word[];
}


