import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ProfileService } from '../../profile.service';
import { MatStepper, MatStep} from '@angular/material/stepper';
import { MessageService } from '../../message.service';

@Component({
  selector:
   'app-chg-pwd',
  templateUrl: './chg-pwd.component.html',
  styleUrls: ['./chg-pwd.component.css']
})
export class ChgPwdComponent implements OnInit {

  oldPwd = new FormControl('', [Validators.required, Validators.minLength(6)]);
  newPwd = new FormControl('', [Validators.required, Validators.minLength(6)]);
  newPwdAgain = new FormControl('', [Validators.required, Validators.minLength(6)]);

  validChange: boolean;
  errMsgChange: string;

  pwdMismatch = false;
  errMismatch = 'Passwords are mismatch.';

  constructor(private router: Router, private profileService: ProfileService, private messageService: MessageService) { }

  private log(uMessage: string) {
    this.messageService.add(`ChgPwdComponent: ${uMessage}`);
  }

  ngOnInit() {
  }

  onChangePwd(uStepper: MatStepper, uStep: MatStep) {
    this.log('onChangePwd starts.');

    if (this.newPwd.value !== this.newPwdAgain.value) {
      this.log('Password mismatch.');
      this.pwdMismatch = true;
      this.newPwdAgain.setErrors({incorrect: true});
      return;
    }

    this.profileService.changePwd(this.oldPwd.value, this.newPwd.value).then(res => {
        console.log(JSON.stringify(res));
        if (res) {
          this.log ('Reset password success.');
          uStep.completed = true;
          uStepper.next();
          setTimeout(() => {
              this.router.navigate(['/home']);
          }, 3000);  // 3s
        } else {
          this.errMsgChange = this.profileService.getErrMsg();
          this.validChange = false;
          this.log('Reset password failed.' + this.errMsgChange);
        }
      }
    );
  }

  getOldPwdErrorMessage() {

    if (this.oldPwd.hasError('required')) {
      return 'You must enter a password.';
    }

    if (this.oldPwd.hasError('minlength')) {
      return 'Password must be more than 6.';
    }

    return '';
  }

  getNewPwdErrorMessage() {

    if (this.newPwd.hasError('required')) {
      return 'You must enter a password.';
    }

    if (this.newPwd.hasError('minlength')) {
      return 'Password must be more than 6.';
    }

    return '';
  }

  getNewPwdAgainErrorMessage() {

    if (this.newPwdAgain.hasError('required')) {
      return 'You must enter a password.';
    }

    if (this.newPwdAgain.hasError('minlength')) {
      return 'Password must be more than 6.';
    }

    return '';
  }
}
