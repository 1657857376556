import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { MessageService } from './message.service';
import { ProfileService } from './profile.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuizResultService {

  userID: string;
  // userEmail: string;
  unitID: number;
  quizTime: number;

  // private resultAddUrl = 'https://arz4ubeyld.execute-api.ap-southeast-1.amazonaws.com/dev';
  // private resultGetUrl = 'https://d2t9xcqel5.execute-api.ap-southeast-1.amazonaws.com/dev';
  // private resultAddUrl = 'https://arz4ubeyld.execute-api.ap-southeast-1.amazonaws.com/prod';
  // private resultGetUrl = 'https://d2t9xcqel5.execute-api.ap-southeast-1.amazonaws.com/prod';

  private resultAddUrl = '';
  private resultGetUrl = '';

  constructor(private http: HttpClient, private messageService: MessageService, private profileService: ProfileService) {
    this.resultAddUrl = environment.apiUrl_ResultAdd;
    this.resultGetUrl = environment.apiUrl_ResultGet;
  }

  private log(uMessage: string) {
    this.messageService.add(`QuizResultService: ${uMessage}`);
  }

  startQuiz(uUsername: string, uQuizID: number) {
    this.userID = uUsername;
    this.unitID = uQuizID;
  }

  setQuizTime( uSec: number) {
    this.quizTime = uSec;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

    // TODO: send the error to remote logging infrastructure
    console.error(error); // log to console instead

    // TODO: better job of transforming error for user consumption
    // Let the app keep running by returning an empty result.
    return of(result as T);
    };
  }

  addPYResult(uUsername: string, uUnitID: number, uCorrect: number, uWrong: number, uSec: number): Observable<QuizResultResp> {

    this.log('addPYProfile starts.');
    // this.log('addProfile username[' + uid + '] , nickname[' + name + ']');

    // token id
    const hdr = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.profileService.getTokenID() });
    const options = { headers: hdr };

    // const body =  { username: uUsername, unitID: uUnitID, correct: uCorrect, wrong: uWrong, seconds: uSec };

    const body =  { userid: uUsername, unitID: uUnitID, correct: uCorrect, wrong: uWrong, seconds: uSec };

    return this.http.post<QuizResultResp>(this.resultAddUrl, body, options)
    .pipe(
      tap(_ => console.log('addPYResult tap')),
      catchError(this.handleError<QuizResultResp>('addPYResult Error'))
    );
  }

  async addResult(uTotal: number, uCorrect: number) {
    this.log('addProfile starts.');
    // this.log('addProfile username[' + uid + '] , nickname[' + name + ']');

    const cntWrong = uTotal - uCorrect;

    // add userID result. UserID and unitID are set during startQuiz.

    this.addPYResult(this.userID, this.unitID, uCorrect, cntWrong, this.quizTime).subscribe(res => {
      this.log('addPYResult complete' + JSON.stringify(res));
    });
  }

  queryPYTranscript(uUsername: string): Observable<TranscriptResp> {
    this.log('queryPYTranscript');

    // token id
    const hdr = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.profileService.getTokenID() });
    const options = { headers: hdr };

    // console.log('queryPYTranscript tokenID:' + this.profileService.getTokenID() );

    this.log('Result get url: ' + this.resultGetUrl);
    this.log('userid: ' + uUsername);

    return this.http.post<TranscriptResp>(this.resultGetUrl, { userid: uUsername }, options )
    .pipe(
      tap(_ => this.log('queryPYTranscript tap')),
      catchError(this.handleError<TranscriptResp>('queryPYTranscript Error'))
    );
  }
}

export interface QuizResultResp {
  statusCode: number;
  body: string;
  RESULT: QuizResult;
}

export interface QuizResult {
  username: string;
  uid: number;
  correct: number;
  wrong: number;
  seconds: number;
}

export interface TranscriptResp {
  statusCode: number;
  body: string;
  RESULTS: UnitResult[];
}

export interface UnitResult {
  unitID: number;
  correct: number;
  seconds: number;
  createdTime: string;
}
