import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  messages: string[] = [];
  debugMode = !environment.production;

  add(message: string) {

    // makya.cc always false for production.
    return;

    if (this.debugMode) {
      this.messages.push(message);
    }
  }

  clear() {
    this.messages = [];
  }
}
