import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../profile.service';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { MatStep } from '@angular/material/stepper';
import { MessageService } from '../../message.service';

@Component({
  selector: 'app-reset-pwd',
  templateUrl: './reset-pwd.component.html',
  styleUrls: ['./reset-pwd.component.css']
})
export class ResetPwdComponent implements OnInit {

  email = new FormControl('', [Validators.required, Validators.email]);
  validSendCode: boolean;
  errMsgSendCode: string;

  password = new FormControl('', [Validators.required, Validators.minLength(6)]);
  passwordAgain = new FormControl('', [Validators.required, Validators.minLength(6)]);
  code = new FormControl('', [Validators.required, Validators.minLength(6)]);
  validReset: boolean;
  errMsgReset: string;

  pwdMismatch = false;
  errMismatch = 'Passwords are mismatch.';

  constructor(private router: Router, private profileService: ProfileService, private messageService: MessageService) { }

  private log(uMessage: string) {
    this.messageService.add(`ResetPwdComponent: ${uMessage}`);
  }

  ngOnInit() {
  }

  onSendCode(uStepper: MatStepper, uStep: MatStep) {
    this.log('onSendCode starts.');

    this.profileService.sendPwdCode(this.email.value).then(res => {
        console.log(JSON.stringify(res));
        if (res) {
          uStep.completed = true;
          uStepper.next();
          this.log ('Send confirmation code success.');
        } else {
          this.errMsgSendCode = this.profileService.getErrMsg();
          this.validSendCode = false;
          this.log('Send confirmation code failed.' + this.errMsgSendCode);
        }
      }
    );
  }

  onResetPwd(uStepper: MatStepper, uStep: MatStep) {
    this.log('Reset password has been submitted');

    if (this.passwordAgain.value !== this.password.value) {
      this.log('Password mismatch.');
      this.pwdMismatch = true;
      // tslint:disable-next-line: object-literal-key-quotes
      this.passwordAgain.setErrors({incorrect: true});
      return;
    }

    this.profileService.resetPwd(this.email.value, this.code.value, this.password.value).then(res => {
        console.log(JSON.stringify(res));

        if (res) {
          uStep.completed = true;
          uStepper.next();
          this.log ('Reset password success.');
          setTimeout(() => {
              this.router.navigate(['/signin']);
          }, 3000);  // 3s
        } else {
          this.errMsgReset = this.profileService.getErrMsg();
          this.validReset = false;
          this.log('Reset password failed.' + this.errMsgReset);
        }
      }
    );

  }

  getEmailErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter a value';
    }

    return this.email.hasError('email') ? 'Not a valid email' : '';
  }

  getPwdErrorMessage() {

    if (this.password.hasError('required')) {
      return 'You must enter a password.';
    }

    if (this.password.hasError('minlength')) {
      return 'Password must be more than 6.';
    }

    return '';
  }

  getPwdAgainErrorMessage() {

    if (this.passwordAgain.hasError('required')) {
      return 'You must enter a password.';
    }

    if (this.passwordAgain.hasError('minlength')) {
      return 'Password must be more than 6.';
    }

    if (this.passwordAgain.value === this.password.valid) {
      return 'Password mismatch.';
    }
    return '';
  }

  getCodeErrorMessage() {

    if (this.password.hasError('required')) {
       return 'You must enter a code';
    }

    return '';
  }

  onSignIn() {
    this.router.navigate(['/signin']);
  }

}
