import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from '../message.service';
import { ShortUrlService } from '../short-url.service';

@Component({
  selector: 'app-go-url',
  templateUrl: './go-url.component.html',
  styleUrls: ['./go-url.component.css']
})
export class GoUrlComponent implements OnInit {

  shortUrl: string;
  longUrl: string;


  constructor(private route: ActivatedRoute, private shortUrlService: ShortUrlService, private messageService: MessageService) { }

  ngOnInit() {

    this.getShortUrl()
    this.redirect()
  }

  private log(uMessage: string) {
    // disable by default
    // this.messageService.add(`GoUrlComponent: ${uMessage}`);
  }

  getShortUrl(): void {
    this.shortUrl = this.route.snapshot.paramMap.get('shorturl');

    this.log('[shorturl]:[' + this.shortUrl + ']')

  }

  redirect() {
    this.log('redirect from [shorturl]:[' + this.shortUrl + ']')

    this.log('Get Url starts.');

    this.shortUrlService.getShortUrl(this.shortUrl).subscribe(data => {
      this.log('GetShortUrl subscribe');
      this.log(JSON.stringify(data));
      this.longUrl = data.longUrl;
      this.go();
    });
    this.log('Get Url ends.');

  }

  go() {
    this.log('go to [longurl]:[' + this.longUrl + ']')

    window.location.href = this.longUrl;

  }

}
