import { AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import { Question } from '../question';
import { QuestionService } from '../question.service';
import { MatPaginator} from '@angular/material/paginator';
import { MatTableDataSource} from '@angular/material/table';
import { ProfileService } from '../profile.service';
import { QuizResultService } from '../quiz-result.service';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.css']
})
export class ResultComponent implements OnInit, AfterViewInit {

  questions: Question[];
  score = 0;
  displayedColumns: string[] = ['id', 'correctAns', 'selectedPinYin', 'selectedAns'];

  dataSource: MatTableDataSource<Question>;
  viewedCount: number;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  constructor(private questionService: QuestionService, private profileService: ProfileService, private quizResultService: QuizResultService) { }

  ngOnInit() {
    this.getQuestionsAWS();
    this.calResult();

    this.dataSource = new MatTableDataSource(this.questions);

    this.viewedCount = this.questionService.viewedCount;

    if (this.profileService.isUserLogined) {
      this.quizResultService.addResult(this.viewedCount, this.score);
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  getQuestionsAWS(): void {
    this.questions = this.questionService.getViewedQuestionsAWS();
  }

  calResult() {
    for (const q of this.questions) {
      if (q.correctAns === q.selectedAns) {
        this.score = this.score + 1;
      }
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = 'false';
  }

  setAll(completed: boolean) {
    if (completed) {
      this.dataSource.filter = 'false';
    } else {
      this.dataSource.filter = '';
    }
  }

}
