/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:44f8f860-b94d-47fe-89a2-2c6c09560c36",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_GuosEJgzh",
    "aws_user_pools_web_client_id": "5f27fmkjqiln6c4e7qb0649gov",
    "oauth": {}
};


export default awsmobile;
